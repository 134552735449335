import uuidv1 from 'uuid/v1';

// HEAD DATA
export const headData = {
  title: 'Mike Damato', // e.g: 'Name | Developer'
  lang: 'en', // e.g: en, es, fr, jp
  description: 'Personal Portfolio', // e.g: Welcome to my website
};

// HERO DATA
export const heroData = {
  greeting: `Greetings!`,
  title: `My name is`,
  name: 'Mike.',
  subtitle: `I'm a Software Engineer.`,
  cta: 'My Projects',
};

const resumeURLStart = `https://drive.google.com/u/0/uc?id=`;
const resumeURLEnd = `&export=download`;
const resumeID = `1AsMVvFMJPwwG2-SpL0D7QcYohrVeuAqq`;

export const aboutData = {
  img: 'profile.jpg',
  paragraphOne: `I am a Software Engineer who enjoys tackling complex problems. I currently work at Bloomberg, LP building out solutions handling large sets of complex data for millions of users world wide.`,
  paragraphTwo:
    'I have also served as an instructor to students attempting to break into the software engineering field. I have worked across 4 cohorts turning great people into great developers.',
  paragraphThree: '',
  resume: `${resumeURLStart}${resumeID}${resumeURLEnd}`,
  // if no resume, the button will not show up
  /* format for google drive
  https://drive.google.com/u/0/uc?id=ID FROM SHAREABLELINK&export=download

  so this resume link from google drive:
  https://drive.google.com/file/d/1749horTyiBycwu0JZmnmy0zRc9aUm67G/view?usp=sharing

  would be this for download:
  https://drive.google.com/u/0/uc?id=1749horTyiBycwu0JZmnmy0zRc9aUm67G&export=download


  https://drive.google.com/file/d/1D1U4-_Eldo587Xc53vVHTPIZsMV26QfV/view?usp=sharing
    */
};

// PROJECTS DATA
export const projectsData = [
  {
    id: uuidv1(),
    img: 'CrispLogo.png',
    title: `Crisp Clothing`,
    info: 'A clothing store selling some of the most fashionable and trendy pieces.',
    info2: 'Please note this app is deployed on Heroku, please be patient!',
    url: 'https://crsp-clothing.herokuapp.com/',
    repo: 'https://github.com/damatomike/clothing-store', // if no repo, the button will not show up
  },
  {
    id: uuidv1(),
    img: 'Whats4DinnerLogo.png',
    title: `What's 4 Dinner?`,
    info:
      'A voice assisted interactive shopping list, recipe generator & cooking instructions provider.',
    info2: '',
    url: 'https://whats4dinner.web.app',
    repo: 'https://github.com/6Gawd/WhatsForDinner3', // if no repo, the button will not show up
  },
  {
    id: uuidv1(),
    img: 'TinyHelpLogo.png',
    title: 'tinyhelp',
    info: 'An open source library for developers',
    info2:
      'This library contains multiple JavaScript functions for developers to speed up their development process.',
    // url: '',
    repo: 'https://github.com/johnptmcdonald/tinyhelp', // if no repo, the button will not show up
  },
  // {
  //   id: uuidv1(),
  //   img: 'GreenShopperLogo.png',
  //   title: 'Green Shopper',
  //   info: 'A fully functional e-commerce website selling high quality plants.',
  //   info2: 'Please note this app is deployed on Heroku, please be patient!',
  //   url: 'https://green-shopper.herokuapp.com/',
  //   repo: 'https://github.com/Green-Shopper/GreenShopper', // if no repo, the button will not show up
  // },
  {
    id: uuidv1(),
    img: 'SmartBrainLogo.png',
    title: 'Smart Brain',
    info: 'A face detection application for highlighting a face in online photos.',
    info2: 'Please note this app is deployed on Heroku, please be patient!',
    url: 'https://smartbrainfacedetector.herokuapp.com/',
    repo: 'https://github.com/damatomike/smartbrain', // if no repo, the button will not show up
  },
];

// CONTACT DATA
export const contactData = {
  cta: 'Get In Touch',
  btn: 'E-Mail Me',
  email: 'damatomike@gmail.com',
};

// FOOTER DATA
export const footerData = {
  networks: [
    {
      id: uuidv1(),
      name: 'linkedin',
      url: 'https://www.linkedin.com/in/michaelvdamato/',
    },
    {
      id: uuidv1(),
      name: 'github',
      url: 'https://github.com/damatomike',
    },
  ],
};

// Github start/fork buttons
export const githubButtons = {
  isEnabled: false, // set to false to disable the GitHub stars/fork buttons
};
